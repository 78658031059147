import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { properties } from './config.js';
import { Auth, API } from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react';

//Amplify.configure(awsExports);
// Auth.configure( {
//   //storage: MyStorage,
//   domain: properties.cognitoDomain,
//   userPoolWebClientId: properties.appClientId,
//   identityPoolId: properties.identityPoolId,
//   userPoolId: properties.userPoolId,
//   region: 'eu-central-1',
//   // Authorized scopes
//   scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//   // Callback URL
//   redirectSignIn: properties.redirectSignIn,
//   // Sign out URL
//   redirectSignOut: properties.redirectSignOut,
//   responseType: 'code',
//   options: {
//     // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//     AdvancedSecurityDataCollectionFlag: true
//   },
//     oauth: {
//       // Domain name
//       domain: properties.cognitoDomain,
//       // Authorized scopes
//       scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//       // Callback URL
//       redirectSignIn: properties.redirectSignIn,
//       // Sign out URL
//       redirectSignOut: properties.redirectSignOut,
//       responseType: 'code',
//       options: {
//         // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//         AdvancedSecurityDataCollectionFlag: true
//       }
//     }
// });

//Amplify.configure(awsExports);


// Amplify.configure({
//   Auth: {
//     //storage: MyStorage,
//     domain: properties.cognitoDomain,
//     userPoolWebClientId: properties.appClientId,
//     identityPoolId: properties.identityPoolId,
//     userPoolId: properties.userPoolId,
//     oauthSignIn: properties.redirectSignIn,
//     region: 'eu-central-1',
//     // Authorized scopes
//     scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//     // Callback URL
//     redirectSignIn: properties.redirectSignIn,
//     // Sign out URL
//     redirectSignOut: properties.redirectSignOut,
//     responseType: 'code',
//     options: {
//       // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//       AdvancedSecurityDataCollectionFlag: true
//     },
//     oauth: {
//       // Domain name
//       domain: properties.cognitoDomain,
//       // Authorized scopes
//       scope:  ['email', 'openid', 'aws.cognito.signin.user.admin'],
//       // Callback URL
//       oauthSignIn: properties.redirectSignIn,
//       federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
//       redirectSignIn: properties.redirectSignIn,
//       // Sign out URL
//       redirectSignOut: properties.redirectSignOut,
//       responseType: 'code',
//       options: {
//         // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//         AdvancedSecurityDataCollectionFlag: true
//       }
//     }
//   }});

ReactDOM.render(
//  <React.StrictMode>
    <AmplifyProvider>
    <App />
    </AmplifyProvider>,
//  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
